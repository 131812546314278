import { mapGetters } from 'vuex'

export default () => {
    return {
        computed: {
            active () {
                return this.emailobj.active
            },
            ...mapGetters({
                emailobj: 'emaildisclaimermodal/emailobj'
            })
        },
        methods: {
            sendEmail () {
                let $this = this,
                    emailAddress = this.emailobj.emailaddress
                
                this.goto("mailto:" + emailAddress)
                setTimeout(function () { 
                    let emailObj = { active: false }
                    $this.$store.dispatch('emaildisclaimermodal/toggle', emailObj)
                 }, 200)
            },
            close () {
                let emailObj = { active: false }
                this.$store.dispatch('emaildisclaimermodal/toggle', emailObj)
            },
            goto (path) {
                location.assign(path)
            }
        }
    }
}