const empty = {
  filters: {}
}
const SearchMixin = ({
  props: {
    url: {
      type: String,
      default: 'searchpage'
    },
    formfilters: {
      type: Object,
      default: () => empty.filters
    }
  },
  data: () => ({
    filters: empty.filters
  }),
  computed: {
    filterList () {
      let filterList = Object.keys(this.filters)
        .map(key => ({
          key,
          value: this.filters[key]
        }))
      return filterList
    },

    hasfilters () {
      return Object.keys(this.filters).length > 0
    },

    queryString () {
      const filterString = this.filterList
        .map(this._normalizeArray)
        .map(({ key, value }) => `${key}=${encodeURIComponent(value)}`)
        .join('&')

      return filterString
        ? `?${filterString}`
        : ''
    },

    fullUrl () {
      return '/' + this.url + this.queryString
    }

  },
  methods: {
    search () {
      window.location.href = this.fullUrl
    },
    updateField (field, value) {
      return new Promise((resolve, reject) => {
        if (typeof this.filters[field] !== 'undefined' && typeof value !== 'undefined') {
          this.filters[field] = value
          setTimeout(() => {
            resolve(this.filters[field])
          }, 1)
        } else {
          resolve()
        }
      })
    },
    _normalizeArray (value) {
      return (value instanceof Array)
        ? value.join(',')
        : value
    }
  },
  mounted () {
    this.filters = this.formfilters
  }

})

export default SearchMixin
