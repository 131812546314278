import Vuex from 'vuex'
import _ from 'underscore'

// Modules
import viewport from './modules/viewport'
import header from './modules/header'
import sticky from './modules/sticky'
import scrolltrigger from './modules/scrolltrigger'
import menupanel from './modules/menupanel'
import searchpanel from './modules/searchpanel'
import pagenav from './modules/pagenav'
import langmenu from './modules/langmenu'
import peoplefeed from './modules/peoplefeed'
import emaildisclaimermodal from './modules/emaildisclaimermodal'
import languagechangemodal from './modules/languagechangemodal'

const debug = (thing, props_, lastProp) => {
  const props = props_ || []
  if (props.length === 0) {
    console.log(thing)
  } else {
    var firstProp = props.shift()
    if (thing) {
      debug(thing[firstProp], props, firstProp)
    } else {
      debug(`Could not find property ${lastProp}.`, [])
    }
  }
  return thing
}

const loadModules = (modules = {}, initialState = {}) => {
  _.each(modules, (module, key) => {
    module.state = _.assign({}, module.state, initialState[key])
  })
  return modules
}

export default (Vue) => {
  if (!Vue) return
  Vue.use(Vuex)
  return new Vuex.Store({
    modules: _.assign(
      {},
      loadModules({
        peoplefeed,
        viewport,
        header,
        sticky,
        scrolltrigger,
        menupanel,
        searchpanel,
        pagenav,
        langmenu,
        emaildisclaimermodal,
        languagechangemodal
      })
    )
  })
}
