/// matches & Closest polyfills
if (!Element.prototype.matches)
    Element.prototype.matches = Element.prototype.msMatchesSelector || Element.prototype.webkitMatchesSelector;
if (!Element.prototype.closest) {
    Element.prototype.closest = function (s) {
        var el = this;
        if (!document.documentElement.contains(el)) return null;
        do {
            if (el.matches(s)) return el;
            el = el.parentElement || el.parentNode;
        } while (el !== null && el.nodeType === 1);
        return null;
    };
}


// REQUIRED ----> Vue Setup: Do not edit
import 'core-js'
import { mapGetters } from 'vuex'
import Vue from 'vue'
import Store from './store'
import './directives'
import _ from 'underscore'

// // ____________________________________________________________________________________________
import Router from 'vue-router'
//import { TweenLite } from 'gsap'
//import ScrollToPlugin from 'gsap/ScrollToPlugin'

// // ____________________________________________________________________________________________

// // Features (as components): Page parts instances
import PageNav from './components/pagenav'
import PageSection from './components/pagesection'
import PageSectionTrigger from './components/pagesectiontrigger'
import Readmore from './components/readmore'
import Carousel from './components/carousel'
import Accordion from './components/accordion'
import PeopleGrid from './components/peoplegrid'
import SiteSearch from './components/sitesearch'
import LandingSearch from './components/landingsearch'
import ToggleState from './components/togglestate'
import VideoLoader from './components/videoloader'
import TypeAhead from './components/typeahead'
import ViewAll from './components/viewall'
import EmailDisclaimer from './components/emaildisclaimer'
import CareersTabs from './components/careerstabs'
import CareersPositions from './components/careerspositions'
import CareersStatsComponent from './components/careersstatscomponent'
import SwiperCarousel from './components/swipercarousel'
import VueWaypoint from 'vue-waypoint'
import searchbox from './components/searchbox'

// Waypoint plugin
Vue.use(VueWaypoint)

const store = Store(Vue)

const router = new Router({
    mode: 'history'
})
Vue.use(Router)

// // Features (as components): Page parts instances
Vue.component('pagenav', PageNav())
Vue.component('sitesearch', SiteSearch())
Vue.component('pagesection', PageSection())
Vue.component('pagesectiontrigger', PageSectionTrigger())
Vue.component('readmore', Readmore())
Vue.component('carousel', Carousel())
Vue.component('accordion', Accordion())
Vue.component('peoplegrid', PeopleGrid())
Vue.component('landingsearch', LandingSearch())
Vue.component('togglestate', ToggleState())
Vue.component('videoloader', VideoLoader())
Vue.component('typeahead', TypeAhead())
Vue.component('viewall', ViewAll())
Vue.component('emaildisclaimer', EmailDisclaimer())
Vue.component('careerstabs', CareersTabs())
Vue.component('careerspositions', CareersPositions())
Vue.component('careersstatscomponent', CareersStatsComponent())
Vue.component('swipercarousel', SwiperCarousel())
Vue.component('searchbox', searchbox())

window.SidebarApp = (!document.getElementById('sidebarapp')) ? 'noncoveoSidebar' : new Vue({
  store,
  router,
  el: '#sidebarapp',
  data: {
    pageready: false
  },
  computed: {
    ...mapGetters({
      mobile: 'viewport/mobile',
      langactive: 'langmenu/active',
      menuactive: 'menupanel/active',
      searchactive: 'searchpanel/active'
    })
  },
  methods: {
    getElementHeight (element) {
      let height = (element) ? element.offsetHeight : null
      return height
    },
    getElementOffset (element) {
      if (!element) return {}
      let de = document.documentElement
      let box = element.getBoundingClientRect()
      let top = box.top + window.pageYOffset - de.clientTop
      let bottom = box.bottom + window.pageYOffset - de.clientTop
      let left = box.left + window.pageXOffset - de.clientLeft
      return { top: top, bottom: bottom, left: left }
    },
    toggleMenu () {
      this.closeSearch()
      this.$store.dispatch('menupanel/toggle')
    },
    closeMenu () {
      this.$store.dispatch('menupanel/toggle', false)
    },
    toggleSearch () {
        this.closeMenu()
        this.$store.dispatch('searchpanel/toggle')
        document.getElementById('header').classList.toggle('header--active')
    },
    closeSearch () {
      this.$store.dispatch('searchpanel/toggle', false)
    },
    documentReady (callback) {
      if (document.readyState !== 'loading') { // in case the document is already rendered
        callback()
      } else {
        document.addEventListener('DOMContentLoaded', callback)
      }
    },
    toggleLang (evt) {
      this.$store.dispatch('langmenu/toggle')
    },
    printPage () {
      window.print()
    },
    globalClick(evt) {
        var store = this.$store;
        document.addEventListener('click', function (evt) {
            if (evt.target.closest('.pagetools__lang')) return;
            store.dispatch('langmenu/toggle', false);
        })
    },
    parseAnchorTags () {
        let anchorTags = document.body.getElementsByTagName("a")
        this.processMailToLinks(anchorTags)
    },
    processMailToLinks (elementArray) {
        let $this = this

        _.each(elementArray, function (element) {
            $this.parseMailToLink(element)
        })
    },
    parseMailToLink (element) {
        let hrefVal = element.getAttribute("href")

        if (hrefVal !== undefined && hrefVal !== null && hrefVal !== '' && hrefVal.toLowerCase().indexOf('mailto:') > -1 && hrefVal.toLowerCase().indexOf('@') > -1) {
            var name = hrefVal.substring(hrefVal.toLowerCase().indexOf('mailto:') + 7, hrefVal.toLowerCase().indexOf('@'));
            var domain = hrefVal.substring(hrefVal.toLowerCase().indexOf('@') + 1);
            var domainsmall = domain;
            if (hrefVal.toLowerCase().indexOf('?') > 0) {
                domainsmall = hrefVal.substring(hrefVal.toLowerCase().indexOf('@') + 1, hrefVal.toLowerCase().indexOf('?'));
            }
            if (name !== undefined && name !== null && name !== '') {
                element.href = 'javascript:globalEmailDisclaimer("' + name + '", "' + domainsmall + '")'
            }
        }
    },
    hasLanguageCodeInLink (link) {
        const languageArray = ["en", "zh-hans", "zh-hant", "nl", "fr", "de", "it", "ja", "pt", "ru", "es"]
        let linkArray = this.splitUrlString(link)
        return linkArray.some(r => languageArray.indexOf(r) >= 0)
    },
    fixExternalLinks () {
        const internalLinkRegex = new RegExp('^((((http:\\/\\/|https:\\/\\/)(www\\.)?)?' + window.location.hostname + ')|(\\/.*))(\\/.*)?$', '')
        const telLinkRegex = new RegExp('^tel:', '')
        const emailLinkRegex = new RegExp('^mailto:', '')
        const jsemailLinkRegex = new RegExp('^javascript:', '')
        const jumpLinkRegex = new RegExp('^#', '')
        const anchors = document.querySelectorAll('a')

        for (let i = 0; i < anchors.length; i++) {
            let href = anchors[i].getAttribute('href')
            if (href && (!internalLinkRegex.test(href) && !telLinkRegex.test(href) && !emailLinkRegex.test(href) && !jsemailLinkRegex.test(href) && !jumpLinkRegex.test(href))) {
                anchors[i].setAttribute('target', '_blank')
            }
        }
    },
    globalEmailDisclaimer (name, domain) {
        var emailTo = name + '@' + domain
        let emailObj = { active: true, emailaddress: emailTo }
        this.$store.dispatch('emaildisclaimermodal/toggle', emailObj)
    },
    getCurrentLanguage () {
        let urlParts = this.splitUrlString(window.location.pathname)
        urlParts.shift()
        return urlParts[0]
    },
    splitUrlString (urlStr) {
        let urlParts = urlStr.split('/')
        return urlParts
    }
  },
  mounted  () {
    this.documentReady(() => {
      this.pageready = true
    })
    this.documentReady(() => {
      this.globalClick()
    })
    this.fixExternalLinks()
    window.globalEmailDisclaimer = this.globalEmailDisclaimer

    this.parseAnchorTags()
  }
})

window.HeaderApp = (!document.getElementById('headervueapp')) ? 'noncoveoHeader' : new Vue({
  store,
  router,
  el: '#headervueapp',
  data: {
    pageready: false
  },
  computed: {
    ...mapGetters({
      mobile: 'viewport/mobile',
      langactive: 'langmenu/active',
      menuactive: 'menupanel/active',
      searchactive: 'searchpanel/active'
    })
  },
  methods: {
    toggleLang () {
      this.$store.dispatch('langmenu/toggle')
    },
    toggleMenu () {
      this.closeSearch()
      this.$store.dispatch('menupanel/toggle')
    },
    closeMenu () {
      this.$store.dispatch('menupanel/toggle', false)
    },
    toggleSearch () {
      this.closeMenu()
      this.$store.dispatch('searchpanel/toggle')
      document.getElementById('header').classList.toggle('header--active')
    },
    closeSearch () {
      this.$store.dispatch('searchpanel/toggle', false)
    },
    submitSearch(){
      
      let inputElement = document.getElementById('header-search-input');
      let searchString = inputElement.value;      
      let languageCoveoText = inputElement.attributes["data-language-text"].value;
      let languageAbbreviation = inputElement.attributes["data-language-abbreviation"].value;
      if(searchString.length>0)
      {        
        window.location.href = "/" + languageAbbreviation + "/site-search#q=" + searchString + "&t=coveo2abeb4ad&sort=relevancy&f:@facetz95xlanguage=[" + languageCoveoText + "]";         
      }
    },    
    documentReady (callback) {
      if (document.readyState !== 'loading') { // in case the document is already rendered
        callback()
      } else {
        document.addEventListener('DOMContentLoaded', callback)
      }
    }
  },
  mounted  () {
    this.documentReady(() => {
      this.pageready = true
    })
  }
})

window.CoveHeroApp = (!document.getElementById('coveoHeroApp')) ? 'nonHeroApp' : new Vue({
  store,
  router,
  el: '#coveoHeroApp',
  data: {
    pageready: false
  },
  computed: {
    ...mapGetters({
      mobile: 'viewport/mobile',
      langactive: 'langmenu/active',
      menuactive: 'menupanel/active',
      searchactive: 'searchpanel/active'
    })
  },
  methods: {
    getElementHeight (element) {
      let height = (element) ? element.offsetHeight : null
      return height
    },
    getElementOffset (element) {
      if (!element) return {}
      let de = document.documentElement
      let box = element.getBoundingClientRect()
      let top = box.top + window.pageYOffset - de.clientTop
      let bottom = box.bottom + window.pageYOffset - de.clientTop
      let left = box.left + window.pageXOffset - de.clientLeft
      return { top: top, bottom: bottom, left: left }
    },
    toggleMenu () {
      this.closeSearch()
      this.$store.dispatch('menupanel/toggle')
    },
    closeMenu () {
      this.$store.dispatch('menupanel/toggle', false)
    },
    toggleSearch () {
        this.closeMenu()
        this.$store.dispatch('searchpanel/toggle')
        document.getElementById('header').classList.toggle('header--active')
    },
    closeSearch () {
      this.$store.dispatch('searchpanel/toggle', false)
    },
    documentReady (callback) {
      if (document.readyState !== 'loading') { // in case the document is already rendered
        callback()
      } else {
        document.addEventListener('DOMContentLoaded', callback)
      }
    }
  },
  mounted  () {
    this.documentReady(() => {
      this.pageready = true
    })
  }
})

// // Bind Vue to page element
window.App = (!document.getElementById('main')) ? 'novueapp' : new Vue({
  store,
  router,
  el: '#main',
  data: {
    pageready: false,
    backgroundregionimage: ''
  },
  computed: {
    pagetab  () {
      return this.$route.query['tab']
    },
    panelactive  () {
      return this.menuactive || this.searchactive
    },
    scrolloffset  () {
      return (this.mobile && this.headerheight) ? this.headerheight : 0
    },
    isMobile  () {
      let truthy = this.getIsMobile()
      return truthy
    },
    ...mapGetters({
      windowposition: 'viewport/positionY',
      mobile: 'viewport/mobile',
      mobilesize: 'viewport/mobilesize',
      menuactive: 'menupanel/active',
      searchactive: 'searchpanel/active',
      haspagenav: 'pagenav/hasnav',
      headerheight: 'header/height',
      pagenavitems: 'pagenav/items'
    })
  },
  watch: {
    '$route' (next, last) {
      if (!!next.query['tab']) {
        this.gotToSection()
      }
    }
  },
  methods: {
    onKeyPress (key) {
      switch (key) {
        case 27: // escape key
          this.closeMenu()
          this.closeSearch()
          break
      }
    },
    toggleMenu () {
      this.closeSearch()
      this.$store.dispatch('menupanel/toggle')
    },
    closeMenu () {
      this.$store.dispatch('menupanel/toggle', false)
    },
    toggleSearch () {
      this.closeMenu()
      this.$store.dispatch('searchpanel/toggle')
      document.getElementById('header').classList.toggle('header--active')
    },
    closeSearch () {
      this.$store.dispatch('searchpanel/toggle', false)
    },
    getElementHeight (element) {
      let height = (element) ? element.offsetHeight : null
      return height
    },
    getElementOffset(element) {
      if (!element) return {}
      let de = document.documentElement
      let box = element.getBoundingClientRect()
      let top = box.top + window.pageYOffset - de.clientTop
      let bottom = box.bottom + window.pageYOffset - de.clientTop
      let left = box.left + window.pageXOffset - de.clientLeft
      return { top: top, bottom: bottom, left: left }
    },
    elementInView (element, offset) {
      offset = _.isNumber(offset) ? offset : 0
      let box = this.getElementOffset(element)
      let is = (box.top > -1 && box.bottom + offset <= window.innerHeight)
      return is
    },
    elementAboveFold (element, offset) {
      offset = _.isNumber(offset) ? offset : 0
      let box = this.getElementOffset(element)
      let is = (box.top + offset <= window.innerHeight)
      return is
    },
    updateTabHistory (id) {
      this.$router.push({ path: this.$route.path, query: { tab: id } })
      this.$emit('tabhistory:update', id)
    },
    gotToSection (where, offset) {
      if (this.pagetab && this.haspagenav) {
        this.$store.dispatch('pagenav/activate', this.pagetab)
      }
    },
    documentReady (callback) {
      if (document.readyState !== 'loading') { // in case the document is already rendered
        callback()
      } else {
        document.addEventListener('DOMContentLoaded', callback)
      }
    },
    speed: function (newY, oldY) {
      const rate = 2
      const ppr = 30 // pixels per rate
      const winY = oldY

      const distance = (newY > winY) ? ((newY - winY)) : ((winY - newY))
      const velocity = (rate / ppr) / 100
      const speed = this.round(distance * velocity)
      return speed
    },
    round: function (v, d) {
      d = (_.isNull(d)) ? 2 : d
      return Number(Math.round(v + 'e' + d) + 'e-' + d)
    },
    getNewY (element) {
      return this.getElementOffset(element).top - this.scrolloffset - 124
    },
    getNewSpeed (y) {
      return (this.mobile) ? 0 : this.speed(y, this.windowposition)
      // return this.speed(y, this.windowposition)
    },
    scrollTo (element) {
    },
    nthClass (styleclass, index, divider) {
      // provide class iteration based on item index and max quantity allowed

      if (arguments.length < 3) {
        throw new Error('nthClass needs 3 parameters')
      }

      let d = (this.mobile) ? 4 : divider

      let indexup = (index + 1)
      let greater = indexup > d
      let level = Math.floor(index / d) + 1
      let calc = (indexup - (d * level)) + d

      let newindex = Math.floor((greater) ? calc : indexup)

      let newclass = styleclass + 'ready ' + styleclass + '' + newindex

      return newclass
    },
    mailPage (subject) {
      const title = document.title
      const newline = '%0A'
      const body = encodeURIComponent(title) + ': ' + newline + newline + encodeURIComponent(document.location.href) + newline + newline
      subject = encodeURIComponent(subject)
      location.assign('mailto:?subject=' + subject + '&body=' + body);
    },
    getIsMobile () {
      const bd = document.body
      const hm = document.documentElement

      const wd = window.innerWidth || hm.clientWidth || bd.clientWidth

      let ismobile = wd < this.mobilesize
      return ismobile
    },
    externalLinkCheck (href) {
        const internalLinkRegex = new RegExp('^((((http:\\/\\/|https:\\/\\/)(www\\.)?)?' + window.location.hostname + ')|(\\/.*))(\\/.*)?$', '')
        const telLinkRegex = new RegExp('^tel:', '')
        const emailLinkRegex = new RegExp('^mailto:', '')
        const jsemailLinkRegex = new RegExp('^javascript:', '')
        const jumpLinkRegex = new RegExp('^#', '')
        
        if (href && (!internalLinkRegex.test(href) && !telLinkRegex.test(href) && !emailLinkRegex.test(href) && !jsemailLinkRegex.test(href) && !jumpLinkRegex.test(href)))
            return true;
        else
            return false;
    }
  },
  mounted () {
    document.onkeydown = (evt) => this.$emit('keypress', (evt ? evt.keyCode : window.event.keyCode))
    this.$on('keypress', this.onKeyPress)
    this.gotToSection()
    this.documentReady(() => {
      this.pageready = true
    })

    if (this.haspagenav && !this.pagetab) {
        this.$router.replace({ path: this.$route.path, query: { tab: this.pagenavitems[0].id }})
    }
  }
})
