export default () => {
    return {
      methods: {
        submitSearch() {
            let string1='';
            let inputElement = document.getElementById('search-input');
            let searchString = inputElement.value;      
            let languageCoveoText = inputElement.attributes["data-language-text"].value;
            let languageAbbreviation = inputElement.attributes["data-language-abbreviation"].value;
            if(searchString.length>0)
            {        
              window.location.href = "/" + languageAbbreviation + "/site-search#q=" + searchString + "&t=coveo2abeb4ad&sort=relevancy&f:@facetz95xlanguage=[" + languageCoveoText + "]";         
            }           
          }
      }
    }
  }
  