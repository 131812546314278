import { mapGetters } from 'vuex'
import _ from 'underscore'

export default () => {
  return {
    props: {
      scrolltosection: {
        type: Boolean,
        default: true
      }
    },
    computed: {
      ...mapGetters({
        mobile: 'viewport/mobile',
        pagesections: 'pagenav/items',
        active: 'pagenav/active'
      })
    },
    watch: {
      mobile (truthy) {
        if (!truthy) {
          if (this.active === null) {
            let section = _.find(this.pagesections, (s, i) => {
              return i === 0
            })

            this.selectSection(section.id)
          }
        }
      }
    },
    methods: {
      isActive (id) {
        return id === this.active
      },
      selectSection (id) {
        let sectionID = _.findIndex(this.pagesections, (section) => section.id === id)

        if (!!this.pagesections[sectionID] && this.pagesections[sectionID].linkOverride !== undefined && this.pagesections[sectionID].linkOverride.url !== undefined && this.pagesections[sectionID].linkOverride.url !== "") {
            
            let fallbackTarget = this.$root.externalLinkCheck(this.pagesections[sectionID].linkOverride.url)
            let target = fallbackTarget ? "_blank" : "_self"
            window.open(this.pagesections[sectionID].linkOverride.url, target)
        
        } else {
          this.$store.dispatch('pagenav/trigger', id)
        }
      }
    }
  }
}
