import { mapGetters } from 'vuex'

export default {
  data: () => ({
    baseheight: 0,
    minheight: 0,
    active: false,
    heightref: 'heightelement'
  }),
  watch: {
    viewportWidth: function () {
      this.$nextTick(function () {
        this.baseheight = this.getHeight()
      })
    }
  },
  computed: {
    heightstyle () {
      let style = (this.isActive()) ? this.baseheight + 'px' : this.minheight + 'px'
      return style
    },
    heightEl () {
      return this.$refs[this.heightref]
    },
    ...mapGetters({
      viewportWidth: 'viewport/width'
    })
  },
  updated: function () {
    this.$nextTick(function () {
      this.baseheight = this.getHeight()
    })
  },
  methods: {
    toggle () {
      this.active = !this.active
    },
    isActive () {
      return this.active
    },
    getHeight () {
      let height = null
      if (this.heightEl) {
        height = (this.heightEl.clientHeight) ? this.heightEl.clientHeight : this.heightEl.offsetHeight
      }
      return height
    }
  }
}
