export default {
  create: (state, payload) => {
    state.items.push(payload)
  },
  trigger: (state, which) => {
    state.triggered = which
  },
  activate: (state, which) => {
    state.active = which
  },
  deactivate: (state, which) => {
    if (state.active === which) state.active = null
  }
}
