import api from 'axios'

export default {
  fetch: function (context) {
    return api.get('/api/peopleapi')
      .then(function (response) {
        context.commit('set', response.data)
      })
  }
}
