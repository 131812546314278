import { mapGetters } from 'vuex'
import activeresize from '../mixins/activeresize'
import _ from 'underscore'

export default () => {
  return {
    props: {
      title: String,
      id: {
        type: String,
        default: 'default'
      },
      linkOverride: {
        type: Object,
        default: () => ({})
      },
      //url: String,
      placeholder: Boolean
    },
    data: () => ({
      heightref: 'tab:heightelement',
      scrolldelay: 1000
    }),
    computed: {
      heightstyle () {
        let style = ''
        if (this.mobile) {
          style = (this.isActive()) ? this.baseheight + 'px' : this.minheight + 'px'
        }
        return style
      },
      ...mapGetters({
        sectionactive: 'pagenav/active',
        mobile: 'viewport/mobile',
        pagesections: 'pagenav/items',
        pagesectiontriggered: 'pagenav/triggered'
      })
    },
    methods: {
      isActive () {
        let active = this.id === this.sectionactive
        this.active = active
        return active
      },
      onHistory (newtab) {
        let match = (newtab === this.id)
        let nil = (newtab === null)

        let activate = (match && !this.isActive())
        let deactivate = ((match || nil) && this.mobile && this.isActive())

        if (activate) {
          this.$store.dispatch('pagenav/activate', this.id)
          if (this.mobile) {
            let timer = window.setTimeout(() => {
              this.$root.scrollTo(this.$el)
              clearTimeout(timer)
            }, this.scrolldelay)
          }
        } else if (deactivate) {
          this.$store.dispatch('pagenav/deactivate', this.id)
        }
      },
      selectSection (id) {
        if (this.placeholder) return

        if (this.url) {
          window.location.href = this.url
        } else {
          this.$root.updateTabHistory(this.id)
        }
      }
    },
    mounted () {
      this.$store.dispatch('pagenav/create', {
        title: this.title,
        id: this.id,
        element: this.$el,
        linkOverride: this.linkOverride
        //url: this.url
      })
      this.$root.$on('tabhistory:update', this.onHistory)
    },
    watch: {
      pagesectiontriggered (id) {
        // this.selectSection(id)
        if (
          (this.mobile && _.isNull(id) && this.sectionactive === this.id) ||
          (id === this.id)
        ) {
          this.selectSection(id)
        }
      }
    },
    mixins: [activeresize]
  }
}
