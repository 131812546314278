export default {
  ready ({ commit }) {
    commit('setReady', true)
  },
  setPosition ({ commit }) {
    const y = window.pageYOffset || document.documentElement.scrollTop

    commit('setWindowPosition', {
      y: y
    })
  },
  setWindowSize ({ commit, dispatch }) {
    dispatch('getWindowSize').then(results => {
      commit('setWindowSize', results)
    })
  },
  getWindowSize () {
    const bd = document.body
    const hm = document.documentElement

    const wd = window.innerWidth || document.documentElement.clientWidth || bd.clientWidth
    const ht = window.innerHeight || document.documentElement.clientHeight || bd.clientHeight
    const sh = Math.max(bd.scrollHeight, bd.offsetHeight, hm.clientHeight, hm.scrollHeight, hm.offsetHeight)

    const device = (typeof window.orientation !== 'undefined') || (navigator.userAgent.indexOf('IEMobile') !== -1)
    const landscape = (window.matchMedia('(orientation: landscape)').matches || window.orientation === 90 || window.orientation === -90)

    return {
      width: wd,
      height: ht,
      scrollheight: sh,
      device: device,
      landscape: landscape
    }
  }
}
