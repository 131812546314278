import Vue from 'vue'

export default Vue.directive('collapse', {
  inserted (el, {name, value}, vnode) {
    const $app = vnode.context
    const self = Vue.directive(name)
    if (!self) throw new Error('Directive is not defined')
    if (!self.$app) self.$app = $app
    if (el.children.length < 1) throw new Error('Element must have at least one child')
    self.onUpdate(value.collapse, el, el.children[0])
  },
  update (el, {name, value}, vnode) {
    const $app = vnode.context
    const self = Vue.directive(name)
    if (!self) throw new Error('Directive is not defined')
    if (!self.$app) self.$app = $app
    if (el.children.length < 1) throw new Error('Element must have at least one child')
    self.onUpdate(value.collapse, el, el.children[0])
  },
  onUpdate (collapse, el, child) {
    el.style.height = (collapse) ? 0 + 'px' : this.getSize(child).height + 'px'
  },
  getSize: function (element) {
    let height = this.$app.$root.getElementHeight(element)
    return {
      height: height
    }
  }
})
