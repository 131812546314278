import _ from 'underscore'
import activeresize from '../mixins/activeresize'
import Vue from 'vue'
import axios from 'axios'

export default () => {
    return {
        props: {
            initialItems: {
                type: Array,
                // will need to update these values based on viewmodel
            },
            pageSize: {
              type: Number,
              default: 2
            },
            pagesToDisplay: {
              type: Number,
              default: 5
            },
            initCurrentPage: {
              type: Number,
              default: 1
            },
            initTotalPages: {
              type: Number,
              default: 10
            },
            initLocation: {
              type: String,
              default: ''
            },
            initParentId: {
              type: String,
              default: ''
            }, 
            iniTitle: {
              type: String,
              default: ''
            },
        },
        data: () => ({
            currentItems: [],
            currentTab: 0,
            currentPage: 1,
            totalPages: 10,
            location: '',
            title: '',
            parentid: ''
        }),
        mounted () {
          this.$nextTick(() => {      
            // Vue.set(this.currentItems, this.initialItems)
            this.currentItems = this.initialItems
            this.currentPage = this.initCurrentPage
            this.totalPages = this.initTotalPages
            this.location = this.initLocation
            this.parentid = this.initParentId
            this.title = this.initTitle
          })
        },
        methods: {
          getPage(pageNum, location, title, parentid) {
            // do api call                  
            axios.get('/api/careers/opportunitysearch', { params: {office: location, position: this.title, parentid: parentid, pageNum: pageNum - 1}})
              .then((data) => {
                this.totalPages = data.data.Pages
                this.currentPage = pageNum
                this.currentItems = data.data.Results
                this.pagesToDisplay = this.totalPages < this.initTotalPages ? this.totalPages : this.pagesToDisplay                
              })
              .catch(console.error)
          },
          getNextPage() {
            return this.getPage(this.currentPage + 1)
          },
          getPreviousPage() {
            return this.getPage(this.currentPage - 1)
          },
          updateLocation() {
            return this.getPage(1, this.location, this.title)
          },
          updateTitle() {            
            return this.getPage(1, this.location, this.title)
          }
        },
        computed: {
          pageRange() {
            if (this.currentPage <= 4) {
              return Array(this.pagesToDisplay).fill().map((_, idx) => idx + 1)
            }

            if (this.currentPage >= this.totalPages - 2) {
              return Array(this.pagesToDisplay).fill().map((_, idx) => this.totalPages + idx - 4)
            }

            return Array(this.pagesToDisplay).fill().map((_, idx) => this.currentPage + (idx-2)).filter(x => x <= this.totalPages)
          }
        }
    }
}
