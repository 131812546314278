import _ from 'underscore'
const Hammer = require('hammerjs')

export default () => {
  return {
    props: {
      withDynamicParent: {
        type: Boolean,
        default: false
      },
      dynamicHeight: {
        type: Boolean,
        default: false
      }
    },
    data: () => ({
      ready: false,
      items: [],
      active: 1,
      next: false,
      previous: false,
      speed: 1200, // milliseconds
      timer: null,
      backward: false
    }),
    computed: {
      count () {
        return this.items.length
      }
    },
    watch: {
      viewportWidth () {
        if (this.dynamicHeight) {
          this.$nextTick(() => {
            this.backtimer = window.setTimeout(() => {
              this.setBaseHeight()
              this.calculateSlideMinHeight(this.active)

              window.clearTimeout(this.backtimer)
              this.backtimer = null
            }, 200)
          })
        }
      },
      active (newVal) {
        if (this.dynamicHeight) {
          this.$nextTick(() => {
            this.calculateSlideMinHeight(newVal)
          })
        }
      }
    },
    methods: {
      setBaseHeight () {
        let computedStyle = window.getComputedStyle(this.$el).minHeight
        this.baseHeight = parseInt(computedStyle.slice(0, computedStyle.indexOf('px')))
      },
      calculateSlideMinHeight (idx) {
        let slideEl = (window.innerWidth < 480 && this.withDynamicParent)
          ? this.$refs[`carousel${idx - 1}`].$refs.dynamicHeightParent
          : this.$refs[`carousel${idx - 1}`].$refs.dynamicHeight

        let slideElHeight = slideEl.getBoundingClientRect().height

        this.slideMinHeight = (slideElHeight > this.baseHeight) ? `${slideElHeight}px` : 'inherit'
      },
      create () {
        let id = this.items.length + 1
        this.items.push({ id: id })
        return _.find(this.items, {'id': id})
      },
      toggle (which) {
        if (this.timer) return false
        let next = which
        let way = (which > this.active) ? 1 : 0
        this.setBackward(way).then(() => {
          this.setPrevious(this.active)
          this.next = next
          this.active = this.next
        })
      },
      transport (way) {
        if (this.timer) return false
        this.setBackward(way).then(() => {
          this.setPrevious(this.active)
          let next = (way) ? this.active + 1 : this.active - 1
          this.next = (next > this.count) ? 1 : (next < 1) ? this.count : next
          this.active = this.next
        })
      },
      setBackward (way) {
        return new Promise((resolve, reject) => {
          this.backward = (way !== 1)
          this.backtimer = window.setTimeout(() => {
            resolve()
            window.clearTimeout(this.backtimer)
            this.backtimer = null
          }, 20)
        })
      },
      setPrevious (which) {
        this.previous = this.active
        this.timer = window.setTimeout(() => {
          this.previous = null
          window.clearTimeout(this.timer)
          this.timer = null
        }, this.speed + 1)
      },
      isActive (which) {
        return this.active === which
      },
      setupTouchEvents () {
        this.touchManager = new Hammer.Manager(this.$refs.belt, {
          recognizers: [[Hammer.Swipe, { direction: Hammer.DIRECTION_HORIZONTAL }]]
        })

        this.touchManager.on('swiperight', (ev) => this.transport(0))
        this.touchManager.on('swipeleft', (ev) => this.transport(1))
      }
    },
    mounted () {
      this.setupTouchEvents()
    },
    components: {
      'carouselitem': {
        data: () => ({
          item: {}
        }),
        computed: {
          id () {
            return this.item.id
          },
          active () {
            return this.$parent.active === this.item.id
          },
          previous () {
            return this.$parent.previous === this.item.id
          },
          state () {
            return (this.active) ? 'active' : (this.previous) ? 'previous' : false
          }
        },
        methods: {
          stateClass (prefix) {
            return (this.state && prefix) ? prefix + this.state : (this.state) ? this.state : false
          }
        },
        mounted () {
          this.item = this.$parent.create()
        }
      }
    }
  }
}
