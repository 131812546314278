export default {
  width: state => state.width,
  height: state => state.height,
  scrollheight: state => state.scrollheight,
  getSize: state => {
    return {
      width: state.width,
      height: state.height,
      scrollheight: state.scrollheight
    }
  },
  position: state => state.position,
  positionY: state => state.position.y,
  positionX: state => state.position.x,
  getPosition: state => {
    return state.position
  },
  ready: state => state.ready,
  headerheight: state => state.headerheight,
  landscape: state => state.landscape,
  device: state => state.device,
  mobile: state => state.mobile,
  mobilesize: state => state.mobilesize,
  small: state => state.small,
  smallsize: state => state.smallsize
}
