import _ from 'underscore'
import activeresize from '../mixins/activeresize'

export default () => {
    return {
        props: {
            opentext: {
                type: String,
                default: 'Open All'
            },
            closetext: {
                type: String,
                default: 'Close All'
            },
            defaultbackgroundregionimage: {
                type: String,
                default: ''
            },
            defaultactive: {
                type: Array,
                default: function () {
                    return [0]
                }
            },
            single: Boolean
        },
        data: () => ({
            items: [],
            active: []
        }),
        computed: {
            toggletext () {
                return (this.active) ? this.closetext : this.opentext
            }
        },
        methods: {
            create () {
                let id = this.items.length
                this.items[id] = { id: id }
                if (!this.$root.isMobile && this.defaultactive.includes(id)) {
                    this.toggle(id)
                }
                return this.items[id]
            },
            toggle (which) {
                const index = _.findIndex(this.active, { id: which })
                if (index !== -1) {
                    this.active = _.reject(this.active, { id: which })
                } else {
                    if (this.single) this.active = []
                    this.active.push({id: which})
                }
            },
            toggleAll () {
                if (this.active.length < this.items.length) {
                    this.active = this.items
                } else {
                    this.active = []
                }
            },
            isActive (which) {
                const index = _.findIndex(this.active, { id: which })
                return index !== -1
            }
        },
        mounted () {
          this.$root.backgroundregionimage = this.defaultbackgroundregionimage
        },
        components: {
            'accordionitem': {
                data: () => ({
                    item: {}
                }),
                computed: {
                    id () {
                        return this.item.id
                    }
                },
                methods: {
                    isActive () {
                        return this.$parent.isActive(this.id)
                    },
                    toggle (regionImage) {
                        this.$parent.toggle(this.id)
                        this.$root.$root.backgroundregionimage = regionImage
                    }
                },
                mounted () {
                    if (this.$parent.create) {
                        this.item = this.$parent.create()
                    }
                },
                mixins: [activeresize]
            }
        }
    }
}
