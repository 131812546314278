import search from '../mixins/search'

export default () => {
  return {
    props: {
      'collapse': {
        type: Boolean,
        default: false
      }
    },
    data: () => ({
      collapseopen: false
    }),
    methods: {
      submit () {
        this.search()
      }
    },
    mixins: [search],
    components: {
      'collapsebutton': {
        template: '<button @click="toggle"></button>',
        methods: {
          toggle () {
            this.$parent.collapseopen = true
          }
        }
      }
    }
  }
}
