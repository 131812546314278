import search from '../mixins/search'

export default () => {
  return {
    methods: {
      submit () {
        this.search()
      }
    },
    mixins: [search]
  }
}