import { mapGetters } from 'vuex'

export default () => {
  return {
    template: '#peoplegrid',
    data: () => ({
      items: []
    }),
    computed: {
      ...mapGetters({
        feed: 'peoplefeed/items'
      })
    },
    watch: {
      feed (feed) {
        this.items = feed
      }
    }
  }
}
