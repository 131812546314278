import _ from 'underscore'
import activeresize from '../mixins/activeresize'
import Vue from 'vue'

export default () => {
    return {
        props: {
            activeLength: {
                type: Number,
                default: 3
            },
        },
        data: () => ({
            items: [],
            active: [],
            currentTab: 0
        }),
        mounted () {
          Vue.set(this.active, new Array(this.activeLength).fill(false))
        },
        methods: {
          setCurrentTab (num) {
            this.currentTab = num
          },
          toggleTab (num) {
            Vue.set(this.active, num, !this.active[num])
          }
        }
    }
}
