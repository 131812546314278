import _ from 'underscore'

export default {
  create: ({commit, dispatch, state}, payload) => {
    return new Promise((resolve, reject) => {
      let key = state.items.length
      let item = _.extend({},
        {
          key: key,
          id: (!_.isNull(payload.id)) ? payload.id : key
        }, payload)
      commit('create', item)
      if (key === 0 && _.findIndex(state.items, state.active) === -1) dispatch('activate', item.id)
      resolve(item.id)
    })
  },
  trigger: ({commit, state}, id) => {
    return new Promise((resolve, reject) => {
      commit('trigger', id)
      resolve(id)
    })
  },
  activate: ({commit, state}, id) => {
    return new Promise((resolve, reject) => {
      let rs = _.findWhere(state.items, {id: id})
      if(rs !== undefined) {
          commit('activate', id)
          resolve(id)
      } else {
          commit('activate', state.items[0].id)
          resolve(state.items[0].id)
      }
    })
  },
  deactivate: ({commit, state}, id) => {
    return new Promise((resolve, reject) => {
      commit('deactivate', id)
      resolve(id)
    })
  }
}