import Vue from 'vue'
import jQuery from 'jquery'
import dotdotdot from 'jquery.dotdotdot'

export default Vue.directive('overflowhide', {
  bind (el, {name}, vnode) {
    // const $app = vnode.context

    if (!jQuery || !dotdotdot) return

    jQuery(document).ready(($) => {
      $(el).dotdotdot({
        // callback: function ( isTruncated ) {}
      })
    })
  }
})
