import { mapGetters } from 'vuex'

export default () => {
  return {
    props: {
      videosrc: String,
      imagesrc: String
    },
    data: () => ({
      pagevideoplaying: true
    }),
    computed: {
      hasvideo () {
        return this.video
      },
      ...mapGetters({
        mobile: 'viewport/mobile'
      }),
      backgroundStyle () {
        return {
          backgroundImage: `url('${this.imagesrc}')`
        }
      }
    },
    methods: {
      togglePageVideoPlay () {
        if (this.pagevideoplaying) {
          this.$refs.video.pause()
          this.pagevideoplaying = false
        } else {
          this.$refs.video.play()
          this.pagevideoplaying = true
        }
      },
    }
  }
}
