import _ from 'underscore'

export default {
  items: state => state.items,
  actives: state => state.actives,
  isactive: (state) => (id) => {
    let item = _.find(state.actives, item => item === id)
    return item !== undefined
  }
}
