import { CountUp } from 'countup.js'
export default () => {
    return {
        data: () => ({
            played: false,
        }),
        methods: {
            onWaypoint ({ el, going, direction }) {
                if (going === 'in' && !this.played) {
                    this.startCountup()
                    this.played = true;
                }            
            },
            startCountup() {
                var countups = []
                const countupElements = document.getElementsByClassName('stat-component__tile-title-number');
                for(var i = 0; i < countupElements.length; i++){
                    countups.push(new CountUp(countupElements[i].id, parseInt(countupElements[i].innerText)))
                }
                countups.forEach(
                    element => element.start()
                )
            }
        }
    }
}