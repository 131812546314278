import { mapGetters } from 'vuex'
import _ from 'underscore'

export default () => {
  return {
    props: {
      id: {
        type: String,
        default: 'default'
      },
      classprefix: {
        type: String,
        default: ''
      }
    },
    computed: {
      section () {
        return _.find(this.pagesections, {'id': this.id})
      },
      title () {
        return this.section.title
      },
      isActive (id) {
        return this.id === this.active
      },
      styleClass () {
        let classes = []
        if (this.isActive) classes.push(this.classprefix + '--active')
        return classes
      },
      ...mapGetters({
        mobile: 'viewport/mobile',
        pagesections: 'pagenav/items',
        active: 'pagenav/active'
      })
    },
    methods: {
      selectSection (id) {
        let sectionID = _.findIndex(this.pagesections, (section) => section.id === id)

        if (!!this.pagesections[sectionID] && this.pagesections[sectionID].linkOverride !== undefined && this.pagesections[sectionID].linkOverride.url !== undefined && this.pagesections[sectionID].linkOverride.url !== "") {
          let fallbackTarget = this.$root.externalLinkCheck(this.pagesections[sectionID].linkOverride.url)
          let target = fallbackTarget ? "_blank" : "_self"
          window.location = this.pagesections[sectionID].linkOverride.url
        } else {
          this.$store.dispatch('pagenav/trigger', (this.isActive) ? null : id)
        }
      }
    },
    template: '<button v-if="mobile" :class="styleClass" @click="selectSection(id, $event)"><span v-html="title"></span><span class="icon section__trigger-icon">&#32;</span></button>'
  }
}
