import Swiper, { Navigation, Pagination } from 'swiper';
Swiper.use([Navigation, Pagination]);

export default () => {
  return {
    mounted () {
      const swiper = new Swiper(this.$el.querySelector('.swiper'), {
        // Optional parameters
        loop: true,

        // If we need pagination
        pagination: {
          el: this.$el.querySelector('.swiper-pagination'),
          bulletClass: 'carousel__circle',
          bulletActiveClass: 'carousel__circle--lt',
          clickable: true,
          renderBullet: function (index, className) {
              return `<button aria-label="select slide ${index}" class="carousel__nav-item carousel__trigger ${className}"></button>`
          }
        },

        // Navigation arrows
        navigation: {
          nextEl: this.$el.querySelector('.swiper-button-next'),
          prevEl: this.$el.querySelector('.swiper-button-prev'),
        },
      });
    },
  }
}
