export default () => {
    return {
        props: {
            searchurl: {
                type: String,
                default: '/site-search'
            },
            section: {
                type: String,
                default: 'Professionals'
            },
            type: {
                type: String
            },
            query: {
                type: String,
                default: ''
            },
            language: {
                type: String,
                default: 'English'
            }
        },
        data: () => ({
        }),
        methods: {
            go () {
                this.buildState();
                CoveoFor1N.redirect(this.searchurl);
            },
            buildState () {
                CoveoFor1N.init();
                CoveoFor1N.removeFromHash('q');
                if (!!this.query && this.query !== '')
                    CoveoFor1N.addToHash('q', this.query, true);
                CoveoFor1N.addToHash('facet_section', this.section);
                if (!!this.type && this.type !== '')
                    CoveoFor1N.addToHash('facet_type', this.type);
                if (!!this.language && this.language !== '')
                    CoveoFor1N.addToHash('facet_language', this.language);
            }
        },
        computed: {
            url () {
                this.buildState();
                return CoveoFor1N.getRedirectUrl(this.searchurl);
            }
        }
    }
}
