import axios from 'axios'

export default () => {
  return {
    props: {
      url: {
        type: String,
        default: '/searchlist'
      },
      keyword: String,
      updatekeyword: Function,
      min: {
        type: Number,
        default: 2
      }
    },
    data: () => ({
      field: null,
      results: [],
      searching: false,
      cansearch: true,
      inside: false
    }),
    computed: {
      hasresults () {
        return this.results.length > 0
      }
    },
    watch: {
      keyword (keyword) {
        if (keyword.length >= this.min) {
          this.query(keyword)
        } else {
          this.clear()
        }
      }
    },
    methods: {
      onkeypress (key) {
        switch (key) {
          case 27 : // escape key
            this.clear()
            break
        }
      },
      onblur (event) {
        if (!this.inside) this.clear()
      },
      query (val) {
        if (!this.searching && this.cansearch) {
          this.searching = true
          return axios.get(this.url, {
            params: {
              keyword: val
            }
          })
            .then(this._success)
            .catch(this._error)
            .then(state => {
              this.searching = false
              return state
            })
        }
      },
      clear () {
        this.results = []
        this.searching = false
        this.cansearch = true
      },
      select (val) {
        this.cansearch = false
        if (typeof this.updatekeyword === 'function') {
          this.updatekeyword('keyword', val).then(() => this.clear())
        } else {
          this.clear()
        }
      },
      _success (res) {
        this.results = res.data || []
        this.searching = false
        return this.results
      },
      _error (err) {
        this.results = []
        console.warn(err)
      }
    },
    components: {
      'typelist': {
        template: '<div><slot :scope="$data"></slot></div>',
        computed: {
          list () {
            return this.$parent.results || []
          },
          hasresults () {
            return this.$parent.hasresults
          },
          results () {
            return this.$parent.results
          }
        },
        methods: {
          select (val) {
            if (typeof val !== 'undefined') {
              this.$parent.select(val)
            }
          }
        }
      }
    },
    mounted () {
      this.field = this.$refs.typeaheadfield
      this.$el.addEventListener('mouseenter', () => (this.inside = true))
      this.$el.addEventListener('mouseleave', () => (this.inside = false))
      this.$root.$on('keypress', this.onkeypress)
    },
    destroyed () {
      this.$el.removeEventListener('mouseenter', () => (this.inside = true))
      this.$el.removeEventListener('mouseleave', () => (this.inside = false))
    }
  }
}
