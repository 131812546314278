import activeresize from '../mixins/activeresize'

export default () => {
  return {
    props: {
      opentext: {
        type: String,
        default: 'Read More'
      },
      closetext: {
        type: String,
        default: 'Read Less'
      }
    },
    computed: {
      toggletext () {
        return (this.active) ? this.closetext : this.opentext
      }
    },
    mixins: [activeresize]
  }
}
