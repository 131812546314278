import _ from 'underscore'

export default {
  create: ({commit, state, getters}, payload) => {
    return new Promise((resolve, reject) => {
      let item = _.extend({},
        {
          id: state.items.length
        }, payload)
      commit('create', item)
      resolve(item.id)
    })
  },
  activate: ({commit, state, getters}, id) => {
    return new Promise((resolve, reject) => {
      commit('activate', id)
      resolve(state.active)
    })
  },
  isactive: ({state}, id) => {
    return new Promise((resolve, reject) => {
      let exists = !_.isUndefined(state.actives.find(item => item === id))
      resolve(exists)
    })
  }
}
