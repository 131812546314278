import activeresize from '../mixins/activeresize'

export default () => {
  return {
    props: {
      default: Boolean
    },
    data: () => ({
      active: false
    }),
    watch: {
      active (active) {
        this.$emit('togglestate', active)
      }
    },
    methods: {
      close () {
        if (this.active) this.active = false
      },
      toggle () {
        this.active = !this.active
      }
    },
    mounted () {
      this.active = this.default
    },
    mixins: [activeresize]
  }
}
